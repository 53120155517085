a {
	text-decoration: none;
	color: white;

	&:hover {
		color: lightgray;
	}
}

li {
	margin-bottom: 12px;
}

body {
	background-color: #282C34;
}

.App-header {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	min-height: 60vh;
	color: white;
}

$glow-1: #FFFFFF;
$glow-3: lightgreen;
$glow-2: lighten($glow-3, 10%);

@keyframes glow {
	from {
		text-shadow: 0 0 10px $glow-1,
		0 0 20px $glow-1,
		0 0 30px $glow-2,
		0 0 40px $glow-2,
		0 0 50px $glow-2,
		0 0 60px $glow-2,
		0 0 70px $glow-2;
	}
	to {
		text-shadow: 0 0 20px $glow-1,
		0 0 30px $glow-3,
		0 0 40px $glow-3,
		0 0 50px $glow-3,
		0 0 60px $glow-3,
		0 0 70px $glow-3,
		0 0 80px $glow-3;
	}
}

@-webkit-keyframes glow {
	from {
		text-shadow: 0 0 10px $glow-1,
		0 0 20px $glow-1,
		0 0 30px $glow-2,
		0 0 40px $glow-2,
		0 0 50px $glow-2,
		0 0 60px $glow-2,
		0 0 70px $glow-2;
	}
	to {
		text-shadow: 0 0 20px $glow-1,
		0 0 30px $glow-3,
		0 0 40px $glow-3,
		0 0 50px $glow-3,
		0 0 60px $glow-3,
		0 0 70px $glow-3,
		0 0 80px $glow-3;
	}
}

.twab {
	padding: 0 15px 30px 15px;
	text-align: center;
	box-sizing: border-box;
	max-width: 100%;

	&__ {
		&heading {
			margin-bottom: 40px;
			margin-top: 40px;
			font-weight: lighter;
			font-size: 2.5rem;
		}

		&wrapper {
			padding: 10px;
			border: 1px solid white;
			display: block;
			margin: 10px 0;
		}

		&link {
			box-sizing: border-box;
			max-width: 100%;
			z-index: 10;
			padding: 15px;
			position: relative;
			backdrop-filter: blur(20px);
			-webkit-backdrop-filter: blur(20px);
			background-image: radial-gradient(rgba(0, 0, 0, 0.3), rgba(255, 0, 0, 0));
			line-height: initial;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&-- {
				&active {
					background-image: radial-gradient(rgba(0, 255, 0, 0.2), rgba(255, 0, 0, 0));

					.twab__title {
						-moz-animation: glow 1s ease-in-out infinite alternate;
						-webkit-animation: glow 1s ease-in-out infinite alternate;
						animation: glow 1s ease-in-out infinite alternate;

						text-decoration: none;
						color: darkgreen;
					}

					&:hover * {
						color: white;
					}
				}
			}
		}

		&title {
			font-size: 2rem;
			font-weight: 700;
			max-width: 100%;

			// this is useless, but pretty
			text-shadow: 0 0 15px rgba(0, 0, 0, 0.3), 0 0 15px rgba(255, 255, 255, 0.3);
		}

		&subtitle {
			margin-top: 10px;
			color: white;
			width: max-content;
			max-width: 100%;
			position: relative;
			padding-top: 10px;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 10%;
				right: 10%;
				border-top: 1px solid rgba(255, 255, 255, 0.5);
			}

		}

		&tagline {
			display: block;
			margin-top: 5px;
		}

		&image {
			// max-width: 600px;
			box-sizing: border-box;

			max-width: 100%;
			padding: 5vmin 5vmin;

			background-position: center;
			background-size: cover;

			z-index: 99;
			position: relative;
			margin: 0;
			line-height: 0;
		}

		&status {
			font-size: 1.5rem;
			font-weight: 600;

			&-- {
				&green {
					color: lightgreen;
				}

				&yellow {
					color: white;
				}

				&red {
					color: indianred;
				}
			}
		}
	}
}

.list {
	display: block;
	padding: 0 15px 30px 15px;

	ul {
		list-style: none;
		padding-left: 0;
	}

	li {
		padding-left: 0;
		margin-left: 0;
	}

	&__ {
		&entry-heading {
			font-weight: bold;
			margin-bottom: 2px;
		}

		&entry-text {
			opacity: 0.7;
		}
	}
}

.credit {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 0.8rem;
	color: #777777;

	a {
		color: #AAAAAA;
	}
}
